/** @jsx jsx */
import HFNMeditate from "../../assets/hfn-meditate.png";

const daajiMenuItems = [
  {
    label: "Dashboard",
    subLabel: "FPMS Dashboard >>",
    to: "/fpms/dashboard",
    icon: HFNMeditate,
    key: "22",
    isTrainer: true,
  },
  {
    label: "Preceptor Announcements Board",
    subLabel: "Add/Update Preceptor Announcments >>",
    to: "/fpms/preceptor-announcements-board",
    icon: HFNMeditate,
    key: "32",
    isTrainer: true,
  },
  {
    label: "Preceptor Mentoring Stats",
    subLabel: "Download Preceptor Mentoring Abhyasis Records >>",
    to: "/fpms/preceptor-mentoring-stats",
    icon: HFNMeditate,
    key: "42",
    isTrainer: true,
  },
];

export default daajiMenuItems;
