/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import DefaultLayout from "../../layouts/dlw-mobile";

import MenuItems from "../../components/MenuItems";
import daajiMenuItems from "../../components/MenuItems/fpmsItems";

const Index = (/* { data } */) => {
  return (
    <DefaultLayout>
      <div sx={{ mt: 4 }}>
        <div sx={{ pt: 2, textAlign: "center" }}>
          <Text variant="header">FPMS</Text>
        </div>
        <MenuItems items={daajiMenuItems} />
      </div>
    </DefaultLayout>
  );
};

export default Index;
